import createStore, { TTextProps } from '@cof/acq-text-ukweb';
import withTemplate from '@cof/acq-text-ukweb/lib/plugins/hocs/withTemplate';

const { withText, TextStore } = createStore({
    hocs: {
        TemplateText: withTemplate,
    }
});

export { withText, TextStore } ;
