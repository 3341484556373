import { ERROR_TYPE } from '../../utilities/constants';

/* eslint-disable max-len */
const ExternalLinks = {
    QUICKCHECK: 'https://quickcheck.capitalone.co.uk'
};

const content = {
    DocumentHead: {
        Title: 'Credit Card Application - Capital One',
        Description: 'Complete our application form for a Capital One credit card. Response in 60 seconds.'
    },
    HiddenText: {
        NewTab: ', opens in a new tab',
        NewWindow: ', opens in a new window',
        PopupWindow: ', opens in a popup window'
    },
    LandingPage: {
        Heading: 'Security Check',
        Paragraph1:
            'Your security is a top priority and we want you to recognise a fraudulent link if you receive one.',
        Paragraph2: 'Please always check the link contains:',
        Paragraph3: 'capitalone.co.uk',
        Button: 'Continue to Capital One'
    },
    Verify: {
        Heading: 'Verify your identity',
        Paragraph1: 'We need to verify your identity, so please enter your date of birth to continue.',
        Button: 'Confirm Identity',
        DateField: {
            Label: 'Date of birth (for example, 26 3 2000)',
            Errors: { 
                Required: 'Date of birth is required.', 
                Invalid: 'This is not a valid date of birth.', 
                day: 'This is not a valid day',
                month: 'This is not a valid month',
                year: 'This is not a valid year'
            }
        },
        IncorrectDateOfBirth: 'Date of birth was incorrect'
    },
    VerifyWithRetries: {
        Button: 'Confirm Identity',
        DateField: {
            Label: 'Please provide your date of birth',
            Errors: {
                Required: 'Date of birth is required.',
                Invalid: 'This is not a valid date of birth.',
                day: 'This is not a valid day',
                month: 'This is not a valid month',
                year: 'This is not a valid year'
            }
        }
    },
    UnifiedDateForm: {
        ContinueButton: 'Continue'
    },
    TechnicalError: {
        [ERROR_TYPE.SERVER_ERROR]: {
            Title: 'There was a problem loading the page',
            List: {
                Description: 'Sorry about this. There are a couple of things you can do:',
                Item1: 'Try the link again now.',
                Item2: 'Give the link another go later on.'
            },
            Paragraph: 'If this keeps happening, you might need to do another QuickCheck.',
            Button: {
                Text: 'Do another QuickCheck',
                URL: ExternalLinks.QUICKCHECK
            }
        }
    }
};

export default content;
