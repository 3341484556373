/**
 * Utility module containing types and type predicates
 */

export interface QueryParams {
    [key: string]: string;
}

type Entries<T> = {
    [K in keyof T]-?: [K, T[K]];
}[keyof T][];

export type Mutable<T> = {
    -readonly [P in keyof T]: T[P];
};

export type Primitive = null | undefined | string | number | boolean | symbol | bigint;

export type CookieKey = '1' | '2' | '3';
export type CookieData = { [k in CookieKey]?: boolean };

export type Dict =
    | Primitive
    | {
          [key: string]: Dict;
      };

// typed Object.keys & values - use only if you know these are the only keys in the object at runtime
export const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>;
export const getEntries = Object.entries as <T>(obj: T) => Entries<T>;

// string tuple type predicate
export const isString = (tpl: [unknown, unknown]): tpl is [string, string] => {
    const [f, s] = tpl;
    return typeof f === 'string' && typeof s === 'string';
};
