import { useEffect, useState } from 'react';
import ukwebconfig, { Dict, PathKey } from '@cof/ukweb-config/lib/clientConfig';
import { Config } from '../../../env/config';
import { Mutable } from '../../utilities/types/types';

const getValues = (paths: PathKey<Config>[], allConfig: Dict) => paths.map((p) => getValue(p, allConfig));

const getValue = (path: PathKey<Config>, allConfig: Dict): Dict => {
    let curr = allConfig;

    // eslint-disable-next-line no-restricted-syntax
    for (const key of `${path}`.split('.')) {
        if (typeof curr === 'object' && curr) curr = curr[key];
        if (typeof curr === 'undefined') {
            throw new Error(`Property ${key} is missing from config. Requested path: ${path}`);
        }
    }
    if (typeof curr === 'string' || typeof curr === 'number' || typeof curr === 'boolean') {
        return curr;
    } else return undefined;
};

const useConfig = (paths: PathKey<Config>[]): { isLoading: boolean; values: Dict[] } => {
    const allConfig = ukwebconfig.get<Dict>();
    const [values, setValues] = useState<Dict[]>(getValues(paths, allConfig));
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        // as assertion is required here to remove readonly so we can subtype as a Dict in getValues
        setValues(getValues(paths, window.config as Mutable<Config>));
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { isLoading, values };
};

export default useConfig;
