import { Dict } from "./types/types";

declare global {
    interface Window {
        newrelic?: {
            addPageAction: (actionName: string, actionObject?: ActionObject) => void;
            noticeError: (error: unknown, customAttributes: Dict) => void;
            setCustomAttribute: (attribute: string, value: string) => void;
        };
    }
}

export const EVENTS = {
    MODAL_OPEN: 'Cookie modal open',
    BANNER_OPEN: 'Cookie banner open',
    GDPR_ACCEPT_ALL: 'GDPR Accept All',
    GDPR_REJECT_ALL: 'GDPR Reject All',
    GDPR_PREF_ALLOWS_1: 'GDPR Pref Allows 1',
    GDPR_PREF_ALLOWS_2: 'GDPR Pref Allows 2',
    GDPR_PREF_ALLOWS_3: 'GDPR Pref Allows 3'
};

interface ActionObject {
    error: string;
}

export const addNewRelicPageAction = (actionName: string, actionObject?: ActionObject): void => {
    window.newrelic?.addPageAction(actionName, actionObject);
};

export const addNewRelicError = (error: Error | string, customAttributes: Dict): void => {
    window.newrelic?.noticeError(error, customAttributes);
};

export const setNewRelicAttribute = (attribute: string, value: string): void => {
    window.newrelic?.setCustomAttribute(attribute, value);
};
