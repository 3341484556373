import { DateInput, Flex, Button, Box, DateInputErrorsProps, DateInputValues, ErrorSummary, ErrorItemProps } from '@cof/plastic-components';
import { useState, useRef } from "react";
import { TText } from '@cof/acq-text-ukweb';
import { Controller, FieldErrors, SubmitHandler, useFormContext } from 'react-hook-form';
import { addNewRelicPageAction } from '../../utilities/newRelic';
import { getKeys } from '../../utilities/types/types';

type InputNamesProps = keyof DateInputValues;

export type InputValues<T> = {
    dateOfBirth: { day: T; year: T; month: T };
};

const formatDateError = (errors: FieldErrors<InputValues<number>>, Text: TText): DateInputErrorsProps => {
    if (errors && Object.keys(errors).length === 0) {
        return null;
    }
    const errorFields: Array<InputNamesProps> = [];

    if (errors.dateOfBirth) {
        getKeys(errors.dateOfBirth).forEach((k) => {
            const v = errors?.dateOfBirth?.[k];
            if (typeof v == 'object' && v.type === 'too_small' && (k === 'day' || k === 'month' || k === 'year')) {
                errorFields.push(k);
            }
        });
    }

    if (errorFields.length > 0) {
        return { message: <Text path="DateField.Errors.Required" />, invalidFields: errorFields };
    }

    if (errors.dateOfBirth?.day?.message) {
        errorFields.push('day');
    }
    if (errors.dateOfBirth?.month?.message) {
        errorFields.push('month');
    }
    if (errors.dateOfBirth?.year?.message || errors.dateOfBirth?.message) {
        errorFields.push('year');
    }
    return { message: <Text path="DateField.Errors.Invalid" />, invalidFields: errorFields };
};

export type DateFormProps = { Text: TText; onSubmit: SubmitHandler<InputValues<number>> };

export const DateForm = ({ Text, onSubmit }: DateFormProps) => {
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useFormContext<InputValues<string>, unknown, InputValues<number>>();
    const [showErrorSummary, setShowErrorSummary] = useState(false);
    const errorSummaryRef = useRef<HTMLDivElement | null>(null);
    
    const onClick = () => {
        setShowErrorSummary(true);
        errorSummaryRef.current?.focus();
    };
    
    function mapErrors(errors: FieldErrors<InputValues<number>>): ErrorItemProps[] {
        const mappedErrors: ErrorItemProps[] = [];
        const dobErrors = errors['dateOfBirth'];
        for (const subKey in dobErrors){
            if(dobErrors.hasOwnProperty(subKey)){
                mappedErrors.push({
                    message: <Text path={`DateField.Errors.${subKey}`} />,
                    reference: `dateOfBirth-${subKey}`
                })
            }
        }
        if (showErrorSummary) {
            return mappedErrors;
        }
        return mappedErrors
    }

    return (
        <form data-qa-id="verify-form" method="post" onSubmit={handleSubmit(onSubmit)}>
            <ErrorSummary items={mapErrors(errors)} ref={errorSummaryRef} id="errorSummary" />
            <Box margin="auto">
                <Controller
                    control={control}
                    name="dateOfBirth"
                    defaultValue={{ day: '', month: '', year: '' }}
                    render={({ field }) => (
                        <DateInput
                            label={<Text path="DateField.Label"/>}
                            {...field}
                            error={formatDateError(errors, Text)}
                        />
                    )}
                />
            </Box>
            <Flex flexDirection="column">
                <Button
                    data-qa-id="submit-dob-button"
                    mt="md"
                    mx="auto"
                    type="submit"
                    onClick={() => {
                        addNewRelicPageAction('Submit button clicked');
                        onClick()
                    }}
                >
                    <Text path="Button" />
                </Button>
            </Flex>
        </form>
    );
};

export default DateForm;
