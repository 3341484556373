import {
    DateInput,
    Flex,
    Button,
    Box,
    Paragraph,
    Text as PlasticText,
    CaretRightIcon,
    LoadingSpinnerBranded,
    BRANDS,
    useBrand,
    DateInputErrorsProps
} from '@cof/plastic-components';
import React, { FunctionComponent } from 'react';
import { Controller, FieldErrors, SubmitHandler, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { withText } from '../../text/textStore';
import { addNewRelicPageAction } from '../../utilities/newRelic';
import { AGE_RANGE_ERROR } from '../../utils';
import { TText } from '@cof/acq-text-ukweb';
import { getEntries } from '../../utilities/types/types';

const StyledConfirmationButton = styled(Button)`
    background-color: ${({ theme }) => theme.components.verify.continueButton};
    border: none;
    width: 100%;
    font-size: 20px;

    &:hover {
        background-color: ${({ theme }) => theme.components.verify.continueButtonHover};
    }

    &:disabled {
        background-color: ${({ theme }) => theme.components.verify.continueButtonDisabled};
    }

    div > div {
        margin-right: 0;
        margin-left: 0;
        padding: 0;
        width: 25px;
    }
`;

type InputNamesProps = 'day' | 'month' | 'year';

const formatDateError = (errors: FieldErrors<InputValues<number>>): DateInputErrorsProps | undefined => {
    if (errors && Object.keys(errors).length === 0) {
        return undefined;
    }
    if (errors?.dateOfBirth?.message === AGE_RANGE_ERROR) {
        return {
            message: (
                <PlasticText color="inherit" data-qa-id="dob-error-message">
                    You must be{' '}
                    <PlasticText fontWeight="medium" color="inherit">
                        aged between 18
                    </PlasticText>{' '}
                    and{' '}
                    <PlasticText fontWeight="medium" color="inherit">
                        95
                    </PlasticText>
                </PlasticText>
            ),
            invalidFields: ['day', 'month', 'year']
        };
    }

    const errorFields: Array<InputNamesProps> = [];
    let isFullyFilled = true;
    if (errors && errors.dateOfBirth) {
        for (const [k, v] of getEntries(errors.dateOfBirth)) {
            if (typeof v == 'object' && v.type === 'too_small') {
                // A required field is empty
                isFullyFilled = false;
            }
            if (k === 'day' || k === 'month' || k === 'year') errorFields.push(k);
        }
    }

    if (!isFullyFilled) {
        return {
            message: (
                <PlasticText color="inherit" data-qa-id="dob-error-message">
                    Your date of birth is{' '}
                    <PlasticText fontWeight="medium" color="inherit">
                        required
                    </PlasticText>
                </PlasticText>
            ),
            invalidFields: errorFields
        };
    }

    const firstErrorField = errorFields[0] ?? null;
    return {
        message: (
            <PlasticText color="inherit">
                Please enter a valid date
                {['day', 'month', 'year'].includes(firstErrorField) && (
                    <Box>
                        <PlasticText color="inherit" data-qa-id="dob-error-message">
                            The{' '}
                            <PlasticText fontWeight="medium" color="inherit">
                                {firstErrorField}
                            </PlasticText>{' '}
                            you entered is invalid
                        </PlasticText>
                    </Box>
                )}
            </PlasticText>
        ),
        invalidFields: errorFields
    };
};

const StyledError = styled(Paragraph)`
    color: ${({ theme }) => theme.components.verify.errorPanel};
`;

type AttemptsRemainingProps = { attemptsRemaining: number };

// TODO: UKFNPM-53829 update attempts remaining === 0 to redirect to correct url param
const AttemptsRemainingError = ({ attemptsRemaining }: AttemptsRemainingProps) => (
    <StyledError data-qa-id="form-error-panel" paddingTop="xs">
        This doesn&apos;t match our records for you. Please try again - you have{' '}
        <span style={{ fontWeight: 'bolder' }}>
            {attemptsRemaining} attempt{attemptsRemaining.toString() !== '1' && 's'}
        </span>{' '}
        left.
    </StyledError>
);

type CaretOrPaddingProps = { brand: string };

const CaretOrPadding = ({ brand }: CaretOrPaddingProps) => (brand === BRANDS.OCEAN ? <CaretRightIcon /> : <div />);

type ConfirmationButtonProps = { brand: string; isSubmitting: boolean; Text: TText };

const ConfirmationButton = ({ brand, isSubmitting, Text }: ConfirmationButtonProps) => (
    <StyledConfirmationButton
        data-qa-id="submit-dob-button"
        mt="md"
        mx="auto"
        type="submit"
        variant="confirmation"
        onClick={() => {
            addNewRelicPageAction('Submit button clicked');
        }}
        disabled={isSubmitting}
    >
        <Flex justifyContent="space-between" alignItems="center">
            {brand === BRANDS.THINK_MONEY.toLowerCase() && <div />}
            <span>
                <Text path="ContinueButton" />
            </span>
            {!isSubmitting ? (
                <CaretOrPadding brand={brand} />
            ) : (
                <LoadingSpinnerBranded
                    color={'global.grey100'}
                    backgroundColor={'global.transparent'}
                    circleSize={25}
                    Icon={null}
                />
            )}
        </Flex>
    </StyledConfirmationButton>
);

export type UnifiedDateFormProps = {
    onSubmit: SubmitHandler<InputValues<number>>;
    attemptsRemaining: number | undefined;
    isSubmitting: boolean;
    Text: TText;
    isBRQ: boolean;
};

export type InputValues<T> = {
    dateOfBirth: { day: T; year: T; month: T };
};

const UnifiedDateForm: FunctionComponent<UnifiedDateFormProps> = ({ onSubmit, attemptsRemaining, isSubmitting, Text, isBRQ }) => {
    const {
        handleSubmit,
        formState: { errors },
        control,
        watch,
        trigger
    } = useFormContext<InputValues<string>, unknown, InputValues<number>>();

    const watchDOB = watch('dateOfBirth');
    React.useEffect(() => {
        if (watchDOB?.day?.length === 2 && watchDOB?.month?.length === 2 && watchDOB?.year?.length === 4) {
            trigger('dateOfBirth');
        }
    }, [watchDOB, trigger]);

    const brand = useBrand();
    return (
        <form data-qa-id="verify-form" method="post" onSubmit={handleSubmit(onSubmit)}>
            <Box margin="auto">
                <Controller
                    render={({ field }) => (
                        <DateInput
                            label={
                                <PlasticText data-qa-id="dob-input-label">
                                    {isBRQ ? (
                                        <>
                                            Please enter your{' '}
                                            <PlasticText fontWeight="medium">date of birth</PlasticText> to check your
                                            saved details and finalise your application.
                                        </>
                                    ) : (
                                        <>
                                            Please provide your{' '}
                                            <PlasticText fontWeight="medium">date of birth</PlasticText>.
                                        </>
                                    )}
                                </PlasticText>
                            }
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            error={formatDateError(errors)}
                            variant="unified"
                            showErrorIcon={false}
                            successOnFilled
                            autoLeadingZeroes
                            autoTab
                            // @ts-ignore TODO might be a missing type in plastic here
                            disabled={isSubmitting}
                        />
                    )}
                    name="dateOfBirth"
                    control={control}
                    defaultValue={{ day: '', month: '', year: '' }}
                />
            </Box>
            {attemptsRemaining !== undefined && <AttemptsRemainingError attemptsRemaining={attemptsRemaining} />}
            <ConfirmationButton brand={brand} isSubmitting={isSubmitting} Text={Text} />
        </form>
    );
};

const UnifiedDateFormWithText = withText('UnifiedDateForm')(UnifiedDateForm);

UnifiedDateFormWithText.displayName = 'UnifiedDateForm';

export default UnifiedDateFormWithText;
