import { ApplicationLayout, useBrand } from '@cof/plastic-components';

import onCookieManagementEvent from './utilities/onCookieManagementEvent';
import { ALLOWED_BRANDS } from './utilities/constants';
import { shouldDisplayVerifyWithRetries } from './utils';

import ErrorBoundary from './components/ErrorBoundary';
import Footer from './components/Footer';
import Header from './components/Header';
import TransformedCookieBanner from './components/TransformedCookieBanner/TransformedCookieBanner';
import TechnicalError from './pages/TechnicalError';
import VerifyWithRetries from './pages/VerifyWithRetries';
import Verify from './pages/Verify';

const ErrorRender = () => (
    <ApplicationLayout Header={Header} Footer={Footer}>
        <TechnicalError />
    </ApplicationLayout>
);
const App = () => {
    const brand = useBrand();

    return (
        <ErrorBoundary renderOnError={<ErrorRender />}>
            <TransformedCookieBanner
                id="cookie-banner"
                onCookieManagementEvent={onCookieManagementEvent}
                allowList={ALLOWED_BRANDS}
            />
            <ApplicationLayout Header={Header} Footer={Footer}>
                {shouldDisplayVerifyWithRetries(brand) ? <VerifyWithRetries /> : <Verify />}
            </ApplicationLayout>
        </ErrorBoundary>
    );
};

export default App;
